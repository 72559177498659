var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "flex items-center cursor-pointer",
        on: {
          click: function ($event) {
            return _vm.$router.push("" + _vm.route)
          },
        },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "16",
              height: "16",
              viewBox: "0 0 16 16",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M15.0005 6.99973H3.83047L8.71047 2.11973C9.10047 1.72973 9.10047 1.08973 8.71047 0.699727C8.32047 0.309727 7.69047 0.309727 7.30047 0.699727L0.710469 7.28973C0.320469 7.67973 0.320469 8.30973 0.710469 8.69973L7.30047 15.2897C7.69047 15.6797 8.32047 15.6797 8.71047 15.2897C9.10047 14.8997 9.10047 14.2697 8.71047 13.8797L3.83047 8.99973H15.0005C15.5505 8.99973 16.0005 8.54973 16.0005 7.99973C16.0005 7.44973 15.5505 6.99973 15.0005 6.99973Z",
                fill: "#1E1D20",
              },
            }),
          ]
        ),
        _c("h2", { staticClass: "font-bold text-xl ml-3" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }